<template>
    <div class="select_store">
        <el-dialog :model-value="Number($store.state.showCenterModal) == 1" :close-on-click-modal="false" title="选择店铺" width="628px" top="25vh" @close="close" >
            <div class="select_center">
                <div class="select_top">
                    <template v-if="cityList.length > 0">
                        <el-select v-model="province" @change="provinceChange" size="small" class="m-2 select_item" placeholder="请选择所在省份">
                            <el-option v-for="(item, index) in (cityList || [])" :key="index" :label="item.provinceName" :value="item.provinceName" />
                        </el-select>
                    </template>
                     <template v-else>
                        <el-select v-model="province" @change="provinceChange" size="small" class="m-2 select_item" placeholder="请选择所在省份">
                            <el-option v-for="(item, index) in (cityList || [])" :key="index" :label="item.provinceName" :value="item.provinceName" />
                        </el-select>
                    </template>
                    <span class="line">-</span>
                    <template v-if="regionCityList.length > 0">
                        <el-select v-model="city" size="small" class="m-2 select_item" placeholder="请选择所在市">
                            <el-option v-for="(item, index) in regionCityList" :key="index" :label="item.cityName" :value="item.nativeCode" />
                        </el-select>
                    </template>
                    <template v-else>
                        <el-select v-model="city" size="small" class="m-2 select_item" placeholder="请选择所在市">
                            <el-option v-for="(item, index) in []" :key="index" :label="item.cityName" :value="item.nativeCode" />
                        </el-select>
                    </template>
                    <div class="search_btn" @click="searchShop">查询</div>
                </div>
                <div class="select_store_list">
                    <div class="store_list_cont">
                        <div class="store_item" :class="curItem.storeId == item.storeId &&'redItem'" v-for="(item, index) in shopList" :key="index" @click="curItem = item">
                            <div class="title">{{ item.dianName || item.storeName }}</div>
                            <div class="detail">{{ item.address}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button class="footer_btn" type="primary" @click="choose">
                        确认选择
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { reactive, ref, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus'

export default {
    name: 'index',
    props: ['cityList'],
    components: {
    },
    setup(props,{ emit }) {
        const { proxy } = getCurrentInstance()
        const store = useStore()
        const regionCityList = ref([])

        const province = ref('')
        const city = ref(null)
        const shopList = ref([])
        const curItem = ref({})
   
        const provinceChange = (val) => {
            const item = (props.cityList || []).find(item => item.provinceName === val);
            regionCityList.value = item.regionCityList;
            city.value = null;
        }
        const searchShop = () => {
            if(!city.value){
                ElMessage('请选择所在市');
                return ;
            }
            proxy.$get('v3/seller/front/store/nearestList', { cityCode: city.value, current: 1,  }).then(res => {
                if (res.state == 200) {
                    shopList.value = res.data;
                }
            })
        }
        const choose = () => {
            if(Object.keys(curItem.value).length == 0){
                ElMessage('未选择店铺');
                return;
            }
            store.commit('setShopItem', curItem.value);
            store.commit('setCenterMoal', 0);
        }
        const close = () => {
            province.value = '';
            city.value = null;
            shopList.value = [];
            curItem.value = {};
            store.commit('setCenterMoal', 0);
        }
        onMounted(() => { })

        return {
            close,
            curItem,
            searchShop,
            shopList,
            regionCityList,
            provinceChange,
            city,
            province,
            choose,
        }
    }
}
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
    .el-dialog__title {
        font-size: 22px !important;
        font-weight: bold !important;
        color: #101010 !important;
    }
    .el-dialog__headerbtn {
        display: none !important;
    }
    .el-dialog__footer {
        text-align: center;
    }
    .el-input__inner {
        border-radius: 0px !important;
    }
    .footer_btn {
        width: 100px;
        // height: 30px;
        background-color: var(--color_main);
        border: 1px solid var(--color_main);
        color: #fff;
        font-size: 14px;
        min-height: 30px !important;
        padding: 0 !important;
    }
}
.select_center {
    .select_top {
        width: 462px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        .select_item {
            width: 140px !important;
            height: 32px !important;
            line-height: 32px !important;
        }
        .line {
            margin: 0 5px;
        }
        .search_btn {
            width: 46px;
            height: 24px;
            border: 1px solid var(--color_main);
            color: var(--color_main);
            font-size: 14px;
            border-radius: 4px;
            line-height: 24px;
            text-align: center;
            margin-left: 13px;
            cursor: pointer;
        }
    }

    .select_store_list {
        width: 462px;
        margin: 20px auto;
        .store_list_cont{
            max-height: 180px;
            overflow-y: auto;
        }
        .store_item {
            width: 100%;
            height: 66px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px;
            border: 1px solid #BBBBBB;
            margin-bottom: 7px;
            cursor: pointer;
            color: #333333;
            font-size: 16px;
        }
        .redItem{
            border: 1px solid red;
        }
    }
}
</style>
  